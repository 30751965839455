import { useMutation, useQuery } from '@tanstack/react-query';
import { registerWithOtp, signInWithOtp } from '../api/cms/auth/service';
import {
    getChargerStatus,
    getSingleChargerStatus,
    startCharging,
    stopCharging,
} from '../api/cms/ev/service';
import {
    getHistoryByUser,
    getLatestHistoryByCharger,
    getStartedHistoryByUser,
} from '../api/cms/history/service';
import { requestOtp, verifyOtp } from '../api/cms/otp/service';
import { queryClient } from './query-client';

export const useRequestOtp = () => {
    type Payload = {
        mobile_number: string;
    };

    return useMutation({
        mutationFn: (payload: Payload) => requestOtp(payload),
    });
};

export const useVerifyOtp = () => {
    type Payload = {
        mobile_number: string;
        otp: string;
    };

    return useMutation({
        mutationFn: (payload: Payload) => verifyOtp(payload),
    });
};

export const useRegisterWithOtp = () => {
    type Payload = {
        username: string;
        mobile_number: string;
        email?: string;
        name?: string;
        car_model?: string;
        car_plate_no?: string;
        otp: string;
    };

    return useMutation({
        mutationFn: (payload: Payload) => registerWithOtp(payload),
    });
};

export const useSignInWithOtp = () => {
    type Payload = {
        username: string;
        otp: string;
    };

    return useMutation({
        mutationFn: (payload: Payload) => signInWithOtp(payload),
    });
};

type Payload = {
    carpark: string;
    charger: string;
    connector: string;
};

export const useGetLatestCmsHistoryByCharger = ({
    carpark,
    charger,
    connector,
}: Payload) => {
    return useQuery({
        queryKey: ['getLatestHistoryByCharger', carpark, charger, connector],
        queryFn: () =>
            getLatestHistoryByCharger({ carpark, charger, connector }),
        enabled: !!carpark && !!charger && !!connector,
        staleTime: Infinity,
        gcTime: 0,
    });
};

export const useGetCmsHistoryByUser = ({
    token,
}: {
    token?: string | null;
}) => {
    return useQuery({
        queryKey: ['getHistoryByUser'],
        queryFn: () => getHistoryByUser(token as string),
        enabled: !!token,
        staleTime: Infinity,
        gcTime: 0,
    });
};

export const useGetStartedHistoryByUser = ({
    token,
}: {
    token?: string | null;
}) => {
    return useQuery({
        queryKey: ['getStartedHistoryByUser'],
        queryFn: () => getStartedHistoryByUser(token as string),
        enabled: !!token,
        staleTime: Infinity,
        gcTime: 0,
    });
};

export const useGetJwt = () => {
    return useQuery({
        queryKey: ['getJwt'],
        queryFn: () => {
            const token = localStorage.getItem('token');
            if (!token) return null;

            const jwt = JSON.parse(atob(token.split('.')[1]));
            const exp = jwt.exp * 1000;
            const now = Date.now();
            if (now > exp) {
                localStorage.removeItem('token');
                return null;
            }

            return token;
        },
        staleTime: Infinity,
        gcTime: 0,
    });
};

export const useDecodeJwt = () => {
    return useQuery({
        queryKey: ['decodeJwt'],
        queryFn: (): { user: any; token: string, car_plate_no?: string } | null => {
            const jwt = localStorage.getItem('token');
            if (!jwt) return null;

            const user: {
                username: string;
                car_plate_no: string;
                iat: number;
                exp: number;
            } = JSON.parse(atob(jwt.split('.')[1]));

            const exp = user.exp * 1000;
            const now = Date.now();
            if (now > exp) {
                localStorage.removeItem('token');
                return null;
            }

            return { user, token: jwt };
        },
        staleTime: Infinity,
        gcTime: 0,
    });
};

export const useLogout = () => {
    return useMutation({
        mutationFn: async () => {
            if (typeof localStorage !== undefined) {
                localStorage.removeItem('token');
                localStorage.removeItem('email');
                localStorage.removeItem('mobile');
            }

            return true;
        },
        onSuccess: () => {
            // invalidate all queries
            queryClient.invalidateQueries();
        }
    });
}

type GetSingleChargerStatus = {
    charger?: string;
    carpark?: string;
    connector?: string;
    transaction?: string;
};

export const useGetChargerStatus = ({
    charger,
    carpark,
}: Omit<GetSingleChargerStatus, 'connector' | 'transaction'>) => {
    return useQuery({
        queryKey: ['getChargerStatus', carpark, charger],
        queryFn: async () => {
            const data = await getChargerStatus({
                chargerIds: [charger],
                carparkId: carpark,
            } as { chargerIds: [string]; carparkId: string });
            return data;
        },
        staleTime: Infinity,
        enabled: charger !== undefined && carpark !== undefined,
        gcTime: 0,
        refetchOnWindowFocus: true,
        refetchInterval: 5000,
    });
};

export const useGetSingleChargerStatus = ({
    charger,
    carpark,
    connector,
    transaction,
}: GetSingleChargerStatus) => {
    return useQuery({
        queryKey: ['getSingleChargerStatus'],
        queryFn: async () => {
            const data = await getSingleChargerStatus({
                charger,
                carpark,
                connector,
                transaction,
            } as {
                charger: string;
                carpark: string;
                connector: string;
            });
            return data;
        },
        enabled:
            charger !== undefined &&
            carpark !== undefined &&
            connector !== undefined,
        staleTime: Infinity,
        gcTime: 0,
        refetchOnWindowFocus: true,
        refetchInterval: 2000,
    });
};

type StartChargingReq = {
    carparkId: string;
    chargerId: string;
    connectorNo: string;
    duration: number;
    userToken: string;
};

export const useStartCharging = ({
    carparkId,
    chargerId,
    connectorNo,
}: Pick<StartChargingReq, 'carparkId' | 'chargerId' | 'connectorNo'>) => {
    return useMutation({
        mutationFn: async ({
            duration,
            userToken,
        }: Pick<StartChargingReq, 'duration' | 'userToken'>) => {
            const reqData = {
                carparkId,
                chargerId,
                connectorNo,
                duration,
            };

            const transactionId = await startCharging(reqData, userToken);
            return transactionId;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getChargerStatus', carparkId, chargerId],
            });
            queryClient.invalidateQueries({
                queryKey: ['getLatestHistoryByCharger', carparkId, chargerId, connectorNo],
            });
        },
    });
};

export const useStopCharging = ({
    carparkId,
    chargerId,
    connectorNo,
}: Pick<StartChargingReq, 'carparkId' | 'chargerId' | 'connectorNo'>) => {
    return useMutation({
        mutationFn: async () => {
            return await stopCharging({
                carparkId,
                chargerId,
                connectorNo,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getChargerStatus', carparkId, chargerId],
            });
        },
    });
};
