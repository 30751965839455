import { FC } from 'react';

type ButtonProps = {
    disabled?: boolean;
    loading?: boolean;
    children: React.ReactNode;
    color?: 'white' | 'primary';
    type?: 'submit' | 'button' | 'reset';
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    loadingText?: string;
};

const Button: FC<ButtonProps> = ({
    disabled,
    loading,
    loadingText = 'Loading...',
    children,
    color = 'white',
    type,
    onClick,
    className,
}) => {
    const backgroundColor = color === 'white' ? 'bg-white' : 'bg-primary';
    const textColor = color === 'white' ? 'text-primary' : 'text-white';
    const hoverColor =
        color === 'white'
            ? 'hover:bg-primary hover:text-white'
            : 'hover:bg-black hover:text-white hover:border-black';

    return (
        <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`h-12 px-3 border border-primary w-full
              font-bold text-base tracking-wide text-primary rounded-md my-2
              ${textColor} ${backgroundColor} ${hoverColor}
              transition-colors duration-200 ${className}
              ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}
              `}
            type={type}
        >
            {loading ? loadingText : children}
        </button>
    );
};

export default Button;
