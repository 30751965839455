'use client';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { FC, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import Button from '../components/button';
import Footer from '../components/footer';
import LandingBanner from '../components/landing-banner';
import { structUrl } from '../utils/helpers';

type Props = {
    children: ReactNode;
};

const HomeLayout: FC<Props> = ({ children }) => {
    const { ref } = useInView();
    const params = useSearchParams();
    const carpark = params.get('carpark');
    const charger = params.get('charger');

    return (
        <>
            {/* <Navbar hasLogo={!inView} /> */}
            <section className="pb-10">
                <div
                    ref={ref}
                    className="flex gap-x-10 items-center justify-start h-12 px-5"
                >
                    <Image
                        width={91}
                        height={7}
                        alt="volvo logo"
                        src="/volvo-logo.svg"
                    />
                </div>

                <div
                    className="relative h-56 w-full flex justify-center"
                    style={{
                        background: "url('/landing-bg-1.svg')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div className="my-2 px-4 flex items-center flex-col justify-between">
                        <p className="uppercase text-base text-white text-transparent text-center">
                            Electrify to the fullest
                        </p>
                        <p className="uppercase text-base text-white text-transparent text-center">
                            {/* VOLVO EV CHARGER ELECTRIFY TO THE FULLEST */}
                        </p>
                    </div>
                </div>

                <div
                    style={{
                        background:
                            'linear-gradient(179.83deg, #08151E 0.14%, #0E263E 99.86%)',
                    }}
                    className="py-4 w-full"
                >
                    <div className="flex h-full items-center justify-center text-white gap-x-5">
                        <div className="w-48 text-center text-sm">
                            <p className="leading-6">
                                Register as a Volvo Friend to get HK$200 RHKYC
                                credit
                            </p>
                            <Link
                                href={
                                    structUrl('/', { carpark, charger }) +
                                    '#register-form'
                                }
                            >
                                <Button>Register Now</Button>
                            </Link>

                            <Link
                                href={
                                    structUrl('/', { carpark, charger }) +
                                    '#login'
                                }
                            >
                                <p className="text-xs leading-6 mb-8 underline">
                                    Already registered? Login here
                                </p>
                            </Link>

                            <div className="leading-6">
                                <p>
                                    Not yet ready to join us now? Login as a
                                    guest to start charging
                                </p>
                                <Link
                                    href={
                                        structUrl('/guest', {
                                            carpark,
                                            charger,
                                        }) + '#guestLogin'
                                    }
                                >
                                    <Button>Guest Login</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <LandingBanner />

                {children}

                <Footer />
            </section>
        </>
    );
};

export default HomeLayout;
