import Image from 'next/image';
import React, { FC } from 'react';
import Link from 'next/link';

const Footer: FC = () => {
    return (
        <footer className="px-10 mt-10 flex flex-col gap-y-10">
            <p className="text-justify text-xs">
                We are committed to protecting your personal data. The personal
                data collected in this form is protected under the Personal Data
                (Privacy) Ordinance of the Hong Kong Special Administrative
                Region. Volvo Cars Hong Kong will only use your personal data
                for direct marketing purposes related to specified products and
                services. We may provide and/or share personal data with our
                third-party service providers, agents, and contractors for the
                aforementioned purposes. If you need to update or modify the
                personal data you have provided, or exercise your rights under
                the applicable Personal Data (Privacy) Ordinance, please call
                2927 3388.{' '}
            </p>

            <p className="text-justify text-xs">
                Subject to terms and conditions,{' '}
                <Link className="underline" href="/tnc">
                    click here
                </Link>{' '}
                for more details. Photos are for reference only. In case of any
                dispute, Wearnes Motors (HK) Limited reserves the right for the
                final decision.
            </p>

            <div>
                <div className="flex items-center gap-x-4">
                    <Link
                        href="https://www.youtube.com/VolvoCarsHK"
                        target="_blank"
                    >
                        <Image
                            width={17}
                            height={12}
                            alt="youtube"
                            src="/youtube.svg"
                        />
                    </Link>
                    <Link
                        href="https://www.linkedin.com/company/wearnes-motors-hk-limited/"
                        target="_blank"
                    >
                        <Image
                            width={13}
                            height={13}
                            alt="linkedin"
                            src="/linkedin.svg"
                        />
                    </Link>
                    <Link
                        href="https://www.facebook.com/VolvoCarsHK"
                        target="_blank"
                    >
                        <Image
                            width={6}
                            height={13}
                            alt="facebook"
                            src="/facebook.svg"
                        />
                    </Link>
                    <Link
                        href="https://www.instagram.com/volvocarshongkong/"
                        target="_blank"
                    >
                        <Image
                            width={13}
                            height={13}
                            alt="instagram"
                            src="/ig.svg"
                        />
                    </Link>
                </div>
                <p className="mt-2 text-xs">
                    Copyright © 2024 Wearnes Motors (HK) Limited
                </p>
            </div>
        </footer>
    );
};

export default Footer;
