import Image from 'next/image'
import React, { FC } from 'react'

const LandingBanner:FC = () => {

  function scrollToRegister() {
    const el = document.getElementById('register-form')
    if (el)
      el.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div 
      style={{
        background: "url('/landing-bg-2.svg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      className="relative text-white bg-gray-500 h-[420px] w-full flex justify-center">
      <div className="mt-5 flex items-center flex-col justify-start">
        {/*
        <p className="uppercase text-base text-transparent">Register now</p>
        <p className="text-2xl uppercase">REGISTER FOR RECHARGING</p>
        <button 
          onClick={scrollToRegister}
          className="mt-4"
          type="button">
          <Image 
            width={20}
            height={24}
            alt="arrow"
            src="/arrow-down.svg"
          />
        </button>
        */}
      </div>
    </div>
  )
}

export default LandingBanner