import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const LoginSchema = z.object({
  // email: z.string().email().trim().min(1, { message: 'Email is required'}),
  mobileNumber: z.string().trim().min(1, { message: 'Mobile number is required'})
})

const RegisterSchema = z.object({
  name: z.string().trim().min(1, { message: 'Name is required'}),
  email: z.string().trim().min(1, { message: "Email is required" }),
  carModel: z.string().trim().min(1, { message: "Car model is required"}),
  carPlateNo: z.string().trim().min(1, { message: "Car Plate No. is required"}),
  mobileNumber: z.coerce
    .number({ required_error: 'Mobile number is required', invalid_type_error: 'Please enter valid HK number' })
    .int({ message: 'Mobile number is required' })
    .positive({ message: 'Mobile number is required '})
    .min(8, { message: "Please enter valid HK number" }),
})

export type LoginDTO = z.infer<typeof LoginSchema>
export const loginResolver = zodResolver(LoginSchema)

export type RegisterDTO = z.infer<typeof RegisterSchema>
export const registerResolver = zodResolver(RegisterSchema)
