'use client'

import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

type Props = {
  type?: 'string' | 'number'
  placeholder: string
  name: string
  otp?: boolean
  disabled?: boolean
}

const Input:FC<Props> = ({ name, otp, disabled = false, placeholder, type = "string" }) => {
  const { register, watch, formState: { errors } } = useFormContext()

  const error = useMemo(() => {
    return errors[name]?.message
  }, [name, errors])

  const value = watch(name)

  return (
    <div className="w-full">
      {value && <p className="text-xs text-gray-500 text-center">{placeholder}</p>}
      <input 
        {...register(name)}
        disabled={disabled}
        maxLength={otp ? 4 : undefined}
        className={`w-full disabled:opacity-60 disabled:cursor-progress outline-0 transition placeholder:text-sm duration-200 border-b text-center pb-2 px-2 ${error ? 'border-[#bb834a] placeholder:text-[#bb834a]' : 'border-black placeholder:text-black'}`}
        placeholder={placeholder}
        type={type} />
    </div>
  )
}

export default Input