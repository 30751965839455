import { isNil } from "lodash";

function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function checkCarpark(carparkId: string): boolean {
  const cp = process.env.NEXT_PUBLIC_ALLOWED_CARPARK
  let res: boolean = false

  if (cp) {
    const allowedCarpark: string[] = cp?.split(',')

    if (allowedCarpark.includes(carparkId)) {
      res = true
    }
  }

  return res
}

function structUrl(path: string, obj: Record<string, any>) {
  const url = new URLSearchParams()

  Object.keys(obj).forEach((key) => {
    const val = obj[key]

    if (!isNil(val))
      url.append(key, val)
  })

  return `${path}?${url.toString()}`
}

export { makeid, checkCarpark, structUrl }